// import React from 'react';
// import './NAACSupport.css';

// const NAACSupport = () => {
//   return (
//     <section className="naacSupport">
//       <div className="naacSupport__header">
//         <h2 className="naacSupport__title">NAAC Accreditation Support</h2>
//         <p className="naacSupport__description">
//           Institutions can leverage CodeXodus to showcase their best practices in integrating technology into education, a critical aspect of NAAC accreditation. The platform's advanced features and positive impact on student learning can be highlighted in accreditation reports.
//         </p>
//       </div>
//       <div className="naacSupport__grid">
//         <div className="naacSupport__item">
//           <h4 className="naacSupport__item-title">Enhancing Quality of Education, Innovative Teaching Practices</h4>
//           <span className="naacSupport__badge">CRITERIA 2, 3</span>
//         </div>
//         <div className="naacSupport__item">
//           <h4 className="naacSupport__item-title">Data-Driven Decision Making</h4>
//           <span className="naacSupport__badge">CRITERIA 2, 6</span>
//         </div>
//         <div className="naacSupport__item">
//           <h4 className="naacSupport__item-title">Outcome-Based Education (OBE)</h4>
//           <span className="naacSupport__badge">CRITERIA 1, 2</span>
//         </div>
//         <div className="naacSupport__item">
//           <h4 className="naacSupport__item-title">Support for Inclusivity and Accessibility</h4>
//           <span className="naacSupport__badge">CRITERIA 2, 7</span>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default NAACSupport;

import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './NAACSupport.css';

const NAACSupport = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS with a duration of 1200ms
  }, []);

  return (
    <section className="naacSupport">
      <div className="naacSupport__header" data-aos="fade-up">
        <h2 className="naacSupport__title">NAAC Accreditation Support</h2>
        <p className="naacSupport__description">
          CodeXodus helps institutions demonstrate excellence in technology-integrated education, enhancing accreditation outcomes by showcasing innovative practices and measurable student success.

        </p>
      </div>
      <div className="naacSupport__grid">
        {/* Quality Education & Teaching Practices */}
        <div className="naacSupport__item" data-aos="fade-right" data-aos-delay="200">
          <h4 className="naacSupport__item-title">
            Quality Education & Teaching Practices
          </h4>
          <p className="naacSupport__item-description">
            Enhances the quality of education through innovative teaching methods.
          </p>
          <span className="naacSupport__badge">CRITERIA 2, 3</span>
        </div>

        {/* Data-Driven Insights */}
        <div className="naacSupport__item" data-aos="fade-left" data-aos-delay="400">
          <h4 className="naacSupport__item-title">Data-Driven Insights</h4>
          <p className="naacSupport__item-description">
            Facilitates effective decision-making based on data analysis.
          </p>
          <span className="naacSupport__badge">CRITERIA 2, 6</span>
        </div>

        {/* Outcome-Based Education */}
        <div className="naacSupport__item" data-aos="fade-right" data-aos-delay="600">
          <h4 className="naacSupport__item-title">Outcome-Based Education (OBE)</h4>
          <p className="naacSupport__item-description">
            Focuses on achieving well-defined educational outcomes.
          </p>
          <span className="naacSupport__badge">CRITERIA 1, 2</span>
        </div>

        {/* Inclusivity & Accessibility */}
        <div className="naacSupport__item" data-aos="fade-left" data-aos-delay="800">
          <h4 className="naacSupport__item-title">Inclusivity & Accessibility</h4>
          <p className="naacSupport__item-description">
            Promotes inclusive education and accessibility for all students.
          </p>
          <span className="naacSupport__badge">CRITERIA 2, 7</span>
        </div>
      </div>

    </section>
  );
};

export default NAACSupport;
