import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./Common/NavBar";
import Layout from "./Pages/Layout";
import Footer from "./Common/Footer";
import "./App.css";
import Courser from "./Courser";
import useWindowSize from "./useWindowSize";
import whatsappIcon from "./Images/whatsapp.svg";
import ContactUs from "./Pages/ContactUs";

function App() {
  const { width } = useWindowSize();

  return (
    <div className="App">
      <Router>
      {width > 768 && <Courser />}
        <NavBar />
        {/* Define your routes */}
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="contact-us" element={<ContactUs />} />
        </Routes>
        <a
          href="https://wa.me/919894079619"
          className="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={whatsappIcon} alt="WhatsApp" />
        </a>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
