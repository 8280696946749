// import React from "react";
// import "./PlacementAndSDG.css";
// import Image1 from "../Images/Sus1.svg";
// import Image2 from "../Images/Sus2.svg";
// import Image3 from "../Images/group.svg";

// const PlacementAndSDG = () => {
//   return (
//     <div>
//       <section className="placementReadiness">
//         <div className="placementReadiness__content">
//           <div className="placementReadiness__text">
//             <h2 className="placementReadiness__title">
//               Placement Readiness for Students
//             </h2>
//             <div className="placementReadiness__cards">
//               <div className="placementReadiness__card placementReadiness__card--blue">
//                 <h4>Interview Preparation</h4>
//                 <p>
//                   It prepares students for the types of coding assessments
//                   commonly used by companies in their hiring processes.
//                 </p>
//               </div>
//               <div className="placementReadiness__card placementReadiness__card--orange">
//                 <h4>Mock Interviews and Challenges</h4>
//                 <p>
//                   The platform provides mock coding challenges that simulate
//                   real interviews, building students' confidence and readiness
//                   for actual interview scenarios.
//                 </p>
//               </div>
//               <div className="placementReadiness__card placementReadiness__card--black">
//                 <h4>Resume Building</h4>
//                 <p>
//                   Completing coding challenges and earning certificates on
//                   CodeXodus showcases students' coding skills and dedication to
//                   learning, helping them stand out to potential employers.
//                 </p>
//               </div>
//               <div className="placementReadiness__card placementReadiness__card--darkblue">
//                 <h4>Industry-Relevant Skills</h4>
//                 <p>
//                   CodeXodus focuses on industry-relevant skills like code
//                   optimization, algorithmic thinking, and debugging, making
//                   students more attractive and prepared for tech roles.
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="placementReadiness__image">
//             <img src={Image3} alt="Students" />
//           </div>
//         </div>
//       </section>

//       <section className="sdg">
//         <h2 className="sdg__title">Sustainable Development Goals (SDG)</h2>
//         <p className="sdg__description">
//           By aligning with NEP 2020, CodeXodus supports institutions in
//           delivering a modern, inclusive, and skill-oriented education that
//           meets the policy’s goals for transformative learning.
//         </p>
//         <ul className="sdg__list">
//           <li>Quality Education - SDG 4</li>
//           <li>Gender Equality - SDG 5</li>
//           <li>Decent Work and Economic Growth - SDG 8</li>
//           <li>Reduced Inequalities - SDG 10</li>
//         </ul>
//         <div className="sdg__images">
//           <img src={Image1} alt="Classroom 1" />
//           <img src={Image2} alt="Classroom 2" />
//         </div>
//       </section>
//     </div>
//   );
// };

// export default PlacementAndSDG;

// import React from "react";
// import "./PlacementAndSDG.css";
// import Image1 from "../Images/group.svg";
// import Image2 from "../Images/Sus2.svg";
// import Image3 from "../Images/group.svg";

// const PlacementReadiness = () => {
//   return (
//     <div className="placement-readiness">
//       <div className="placement-readiness__header">
//         <img
//           src={Image1}
//           alt="Students"
//           className="placement-readiness__image"
//         />
//         <h1 className="placement-readiness__title">
//           Placement Readiness for Students
//         </h1>
//       </div>

//       <div className="placement-readiness__benefits">
//         <div className="benefit">
//           <h2 className="benefit__title">Interview Preparation</h2>
//           <p className="benefit__description">
//             It prepares students for the types of coding assessments commonly
//             used by companies in their hiring processes.
//           </p>
//         </div>
//         <div className="benefit benefit--orange">
//           <h2 className="benefit__title">Mock Interviews and Challenges</h2>
//           <p className="benefit__description">
//             The platform provides mock coding challenges that simulate real
//             interviews, building students' confidence and readiness for actual
//             interview scenarios.
//           </p>
//         </div>
//         <div className="benefit benefit--black">
//           <h2 className="benefit__title">Resume Building</h2>
//           <p className="benefit__description">
//             Completing coding challenges and earning certificates on CodeXodus
//             showcases students' coding skills and dedication to learning,
//             helping them stand out to potential employers.
//           </p>
//         </div>
//         <div className="benefit">
//           <h2 className="benefit__title">Industry-Relevant Skills</h2>
//           <p className="benefit__description">
//             CodeXodus focuses on industry-relevant skills like code
//             optimization, algorithmic thinking, and debugging, making students
//             more attractive and prepared for tech roles.
//           </p>
//         </div>
//       </div>

//       <div className="placement-readiness__sdg">
//         <h2 className="sdg__title">Sustainable Development Goals(SDG)</h2>
//         <p className="sdg__description">
//           By aligning with NEP 2020, CodeXodus supports institutions in
//           delivering a modern, inclusive, and skill-oriented education that
//           meets the policy's goals for transformative learning.
//         </p>
//         <ul className="sdg__list">
//           <li className="sdg__item">Quality Education – SDG 4</li>
//           <li className="sdg__item">Gender Equality – SDG 5</li>
//           <li className="sdg__item">Decent Work and Economic Growth – SDG 8</li>
//           <li className="sdg__item">Reduced Inequalities – SDG 10</li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default PlacementReadiness;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./PlacementAndSDG.css";
import Image1 from "../Images/group.svg";
import Image2 from "../Images/Sus2.svg";
import Image3 from "../Images/group.svg";

const PlacementReadiness = () => {
  useEffect(() => {
    AOS.init({ duration: 1200, offset: 200 });
  }, []);

  return (
    <div className="placement-readiness">
      <div className="placement-readiness__header" data-aos="fade-in">
        <img
          src={Image1}
          alt="Students"
          className="placement-readiness__image"
          data-aos="fade-right"
        />
        <h1 className="placement-readiness__title" data-aos="fade-left">
          Placement Readiness for Students
        </h1>
      </div>

      <div className="placement-readiness__benefits">
        {/* Interview Preparation */}
        <div className="benefit" data-aos="fade-up">
          <h2 className="benefit__title">Interview Preparation</h2>
          <p className="benefit__description">
            Prepares students using platforms frequently utilized by top companies during their hiring processes, ensuring familiarity and readiness for real assessments.
          </p>
        </div>

        {/* Mock Interviews and Challenges */}
        <div className="benefit benefit--orange" data-aos="fade-up" data-aos-delay="200">
          <h2 className="benefit__title">Mock Interviews and Challenges</h2>
          <p className="benefit__description">
            Provides scheduled mock coding challenges that simulate real interviews, enhancing problem-solving skills and boosting students' confidence in real-world conditions.
          </p>
        </div>

        {/* Certifications and Resume Building */}
        <div className="benefit benefit--black" data-aos="fade-up" data-aos-delay="400">
          <h2 className="benefit__title">Certifications & Resume Building</h2>
          <p className="benefit__description">
            Awards Level 1, 2, and 3 certificates upon successful completion of practice sessions, helping students build strong resumes that demonstrate their coding skills to potential employers.
          </p>
        </div>

        {/* Industry-Relevant Skills */}
        <div className="benefit" data-aos="fade-up" data-aos-delay="600">
          <h2 className="benefit__title">Industry-Relevant Skill Training</h2>
          <p className="benefit__description">
            Focuses on developing key industry skills such as algorithmic thinking, code optimization, and debugging to ensure students are well-prepared for technical roles.
          </p>
        </div>
      </div>


      <div className="placement-readiness__sdg" data-aos="zoom-in">
        <h2 className="sdg__title">Aligning with Sustainable Development Goals (SDG)
        </h2>
        <p className="sdg__description">
          CodeXodus actively supports the vision of Sustainable Development Goals, fostering an inclusive, equitable, and quality education environment.

        </p>
        <ul className="sdg__list">
          {/* SDG 4: Quality Education */}
          <li className="sdg__item" data-aos="fade-right">
            <strong>Quality Education – SDG 4</strong>
            <p>Provide accessible coding tools and comprehensive content to ensure inclusive, quality education for all.</p>
          </li>

          {/* SDG 5: Gender Equality */}
          <li className="sdg__item" data-aos="fade-left" data-aos-delay="100">
            <strong>Gender Equality – SDG 5</strong>
            <p>Foster equal learning opportunities for all genders, promoting diverse participation in tech education.</p>
          </li>

          {/* SDG 8: Decent Work & Economic Growth */}
          <li className="sdg__item" data-aos="fade-right" data-aos-delay="200">
            <strong>Decent Work & Economic Growth – SDG 8</strong>
            <p>Prepare students with industry-relevant skills, ensuring readiness for quality job opportunities and sustainable careers.</p>
          </li>

          {/* SDG 10: Reduced Inequalities */}
          <li className="sdg__item" data-aos="fade-left" data-aos-delay="300">
            <strong>Reduced Inequalities – SDG 10</strong>
            <p>Break barriers with regional language support and flexible learning, making coding education accessible for all.</p>
          </li>
        </ul>

      </div>
    </div>
  );
};

export default PlacementReadiness;
