// import React from "react";
// import "./DemoForm.css";
// import ContactImage from "../Images/Rectangle.svg"; // Replace with the correct path for your image

// const DemoForm = () => {
//   return (
//     <section className="demoForm">
//       <div className="demoForm__image">
//         <img src={ContactImage} alt="Students working" />
//       </div>
//       <div className="demoForm__form-container">
//         <h3 className="demoForm__title">
//           Interested in bringing<br/> <span>CodeXodus</span> to your university?
//         </h3>
//         <form className="demoForm__form">
//           <input
//             type="email"
//             placeholder="Enter your email id"
//             className="demoForm__input"
//             required
//           />
//           <input
//             type="tel"
//             placeholder="Enter your phone number"
//             className="demoForm__input"
//             required
//           />
//           <button type="submit" className="demoForm__button">
//             Book Demo
//           </button>
//         </form>
//       </div>
//     </section>
//   );
// };

// export default DemoForm;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./DemoForm.css";
import ContactImage from "../Images/Rectangle.svg"; // Replace with the correct path for your image

const DemoForm = () => {
  useEffect(() => {
    AOS.init({ duration: 1200, offset: 200 });
  }, []);

  return (
    <section className="demoForm">
      <div className="demoForm__image" data-aos="fade-right">
        <img src={ContactImage} alt="Students working" />
      </div>
      <div className="demoForm__form-container" data-aos="fade-left">
        <h3 className="demoForm__title">
          Interested in bringing<br /> <span>CodeXodus</span> to your university?
        </h3>
        <form className="demoForm__form">
          <input
            type="email"
            placeholder="Enter your email id"
            className="demoForm__input"
            required
          />
          <input
            type="tel"
            placeholder="Enter your phone number"
            className="demoForm__input"
            required
          />
          <button type="submit" className="demoForm__button">
            Book Demo
          </button>
        </form>
      </div>
    </section>
  );
};

export default DemoForm;
