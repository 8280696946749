import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Hero.css";

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  // Function to open the Calendly link in a new tab
  const openDemo = () => {
    window.open("https://calendly.com/help-codexodus/30min", "_blank");
  };

  // Function to redirect to the CodeXodus app
  const startFreeTrial = () => {
    window.open("https://app.codexodus.com", "_blank");
  };

  return (
    <div className="banner">
      <div className="banner__content" data-aos="fade-up">
        <h1 className="banner__title">
          <span className="banner__highlight">
            Transform Classroom Learning into Industry-Ready Skills with
          </span>
        </h1>
        <span className="code">{`<codeXodus/>`}</span>
        <p className="banner__description" data-aos="fade-up" data-aos-delay="200">
          Without Faculty Load, Time-Consuming Manual Grading, or Software Installation Hassles
        </p>
        <p className="banner__description" data-aos="fade-up" data-aos-delay="300">
          Kickstart ICT-Enabled Teaching and Learning, and Bridge the Gap Between Academia and Industry with Ease.
        </p>
        <div className="banner__actions" data-aos="fade-up" data-aos-delay="400">
          <button
            className="banner__button banner__button--primary"
            onClick={startFreeTrial}
          >
            Start Your Free Trial
          </button>
          <button
            className="banner__button banner__button--secondary"
            onClick={openDemo}
          >
            Demo
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
