// import React from "react";
// import "./EnhancedTools.css";
// import Image1 from '../Images/lecture.svg';
// import Image2 from  '../Images/stock.svg';

// const EnhancedTools = () => {
//   return (
//     <section className="enhancedTools">
//       <div className="enhancedTools__grid">
//         <div className="enhancedTools__item enhancedTools__item--wide">
//           <img
//             src={Image1}
//             alt="Enhanced Teaching & Learning Assessment Tools"
//             className="enhancedTools__image"
//           />
//         </div>
//         <div className="enhancedTools__item enhancedTools__item--text">
//           <h3 className="enhancedTools__title">
//             Enhanced Teaching & Learning Assessment Tools
//           </h3>
//           <p className="enhancedTools__description">
//             CodeXodus makes teaching easier with automated grading, flexible
//             schedules, and real-time feedback. Educators can focus more on
//             teaching while tracking student progress efficiently.
//           </p>
//         </div>
//         <div className="enhancedTools__item enhancedTools__item--text">
//           <h3 className="enhancedTools__title">Comprehensive Analytics</h3>
//           <p className="enhancedTools__description">
//             Get clear insights into student performance with detailed reports
//             and progress tracking. CodeXodus helps educators understand where
//             students need improvement and adjust their teaching strategies.
//           </p>
//         </div>
//         {/* <div className="enhancedTools__item">
//           <img
//             src={Image2}
//             alt="Comprehensive Analytics"
//             className="enhancedTools__image"
//           />
//         </div> */}
//       </div>
//       {/* <div className="enhancedTools__cta">
//         <button className="enhancedTools__button">See more</button>
//       </div> */}
//     </section>
//   );
// };

// export default EnhancedTools;


import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./EnhancedTools.css";
import Image1 from '../Images/lecture.svg';
import Image2 from '../Images/stock.svg';

const EnhancedTools = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS with default duration
  }, []);

  return (
    <section className="enhancedTools">
      <div className="enhancedTools__grid">
        <div className="enhancedTools__item enhancedTools__item--wide" data-aos="fade-right">
          <img
            src={Image1}
            alt="Enhanced Teaching & Learning Assessment Tools"
            className="enhancedTools__image"
          />
        </div>
        <div className="enhancedTools__item enhancedTools__item--text" data-aos="fade-left" data-aos-delay="200">
          <h3 className="enhancedTools__title">
            Placement-Focused Training & Insights

          </h3>
          <p className="enhancedTools__description">
            Prepare your students for successful careers with structured, placement-oriented training. CodeXodus provides tailored coding challenges, mock interviews, and skills assessments designed to ensure students are confident and ready for real-world opportunities.

          </p>
        </div>
        <div className="enhancedTools__item enhancedTools__item--text" data-aos="fade-left" data-aos-delay="400">
          <h3 className="enhancedTools__title">Proctored, Secured Exam Management
          </h3>
          <p className="enhancedTools__description">
            Manage tests and assessments seamlessly with customizable schedules, paired with proctoring features to ensure exam integrity. Set up secure testing environments that fit your course structure, providing a stress-free experience for both educators and students.

          </p>
        </div>
      </div>
    </section>
  );
};

export default EnhancedTools;
