// import React from "react";
// import "./SeamlessClassroom.css";
// import Image1 from "../Images/1.svg";
// import Image2 from "../Images/2.svg";
// import Image3 from "../Images/3.svg";

// const SeamlessClassroom = () => {
//   return (
//     <section className="seamlessClassroom">
//       <div className="seamlessClassroom__header">
//         <h3 className="seamlessClassroom__tagline">FACULTY-FRIENDLY TOOLS</h3>
//         <h2 className="seamlessClassroom__title">
//           SEAMLESS CLASSROOM <br />
//           MANAGEMENT
//         </h2>
//         <p className="seamlessClassroom__subtitle">
//           Enhance the classroom experience with virtual coding labs, where
//           students can work collaboratively, ask questions, and get real-time
//           feedback.
//         </p>
//       </div>

//       <div className="seamlessClassroom__features">
//         <div className="seamlessClassroom__feature">
//           <img
//             src={Image1}
//             alt="Assignment Creation & Tracking"
//             className="seamlessClassroom__feature-image"
//           />
//           <h4 className="seamlessClassroom__feature-title">
//             Assignment Creation & Tracking
//           </h4>
//           <p className="seamlessClassroom__feature-description">
//             Easily design custom coding challenges and track student submissions
//             in one place.
//           </p>
//         </div>
//         <div className="seamlessClassroom__feature">
//           <img
//             src={Image2}
//             alt="Student Progress Monitoring"
//             className="seamlessClassroom__feature-image"
//           />
//           <h4 className="seamlessClassroom__feature-title">
//             Student Progress Monitoring
//           </h4>
//           <p className="seamlessClassroom__feature-description">
//             Identify learning gaps early with individual progress reports.
//           </p>
//         </div>
//         <div className="seamlessClassroom__feature">
//           <img
//             src={Image3}
//             alt="Flexible Course Timelines"
//             className="seamlessClassroom__feature-image"
//           />
//           <h4 className="seamlessClassroom__feature-title">
//             Flexible Course Timelines
//           </h4>
//           <p className="seamlessClassroom__feature-description">
//             Schedule tests and assessments that fit the class structure and
//             pace.
//           </p>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default SeamlessClassroom;


import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./SeamlessClassroom.css";
import Image1 from "../Images/1.svg";
import Image2 from "../Images/2.svg";
import Image3 from "../Images/3.svg";

const SeamlessClassroom = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS with default duration
  }, []);

  return (
    <section className="seamlessClassroom">
      <div className="seamlessClassroom__header" data-aos="fade-up">
        <h3 className="seamlessClassroom__tagline">Empowerment Tools for Educators
        </h3>
        <h2 className="seamlessClassroom__title">
          SEAMLESS CLASSROOM <br />
          MANAGEMENT
        </h2>
        <p className="seamlessClassroom__subtitle">
          Enhance the classroom experience with virtual coding labs, where
          students can work collaboratively, ask questions, and get real-time
          feedback.
        </p>
      </div>

      <div className="seamlessClassroom__features">
        <div className="seamlessClassroom__feature" data-aos="fade-right">
          <img
            src={Image1}
            alt="Assignment Creation & Tracking"
            className="seamlessClassroom__feature-image"
          />
          <h4 className="seamlessClassroom__feature-title">
            AI-Enhanced Teaching & Learning Tools

          </h4>
          <p className="seamlessClassroom__feature-description">
          Transform teaching with AI support for automated grading, scheduling, and personalized feedback, allowing educators to focus on impactful instruction.
          </p>
        </div>
        <div className="seamlessClassroom__feature" data-aos="fade-up" data-aos-delay="200">
          <img
            src={Image2}
            alt="Student Progress Monitoring"
            className="seamlessClassroom__feature-image"
          />
          <h4 className="seamlessClassroom__feature-title">
            Actionable Analytics for Better Outcomes

          </h4>
          <p className="seamlessClassroom__feature-description">
          Gain insights into student progress, enabling personalized support and enhancing placement readiness.

          </p>
        </div>
        <div className="seamlessClassroom__feature" data-aos="fade-left" data-aos-delay="400">
          <img
            src={Image3}
            alt="Flexible Course Timelines"
            className="seamlessClassroom__feature-image"
          />
          <h4 className="seamlessClassroom__feature-title">
            Inclusive Learning with Regional Language Support

          </h4>
          <p className="seamlessClassroom__feature-description">
          Provide coding education in regional languages, ensuring accessibility and inclusivity for all students.

          </p>
        </div>
      </div>
    </section>
  );
};

export default SeamlessClassroom;
