
import Hero from "./Hero";
import WhyCodeXodus from "./WhyCodeXodus";
import SeamlessClassroom from "../Common/SeamlessClassroom";
import EnhancedTools from "./EnhancedTools";
import NEP2020 from "./NEP2020";
import NAACSupport from "./NAACSupport";
import NBASupport from "./NBASupport";
import PlacementAndSDG from "./PlacementAndSDG";
import DemoForm from "./DemoForm";

function App() {
    return (
        <div >

            <Hero />
            <WhyCodeXodus />
            <SeamlessClassroom />
            <EnhancedTools />
            <NEP2020 />
            <NAACSupport />
            <NBASupport />
            <PlacementAndSDG />
            <DemoForm />


        </div>
    );
}

export default App;
