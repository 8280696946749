// import React from "react";
// import "./WhyCodeXodus.css";
// import Image1 from "../Images/why1.svg";
// import Image2 from "../Images/why2.svg";
// import Image3 from "../Images/why3.svg";

// const WhyCodeXodus = () => {
//   return (
//     <section className="whyCodeXodus">
//       <h2 className="whyCodeXodus__title">Why CodeXodus for Universities?</h2>

//       <div className="whyCodeXodus__content">
//         <div className="whyCodeXodus__images">
//           <img
//             src={Image1}
//             alt="Students walking"
//             className="whyCodeXodus__image whyCodeXodus__image--left"
//           />

//         </div>

//         <div className="whyCodeXodus__info">
//           <p className="whyCodeXodus__description">
//             CodeXodus provides a complete solution to enhance university coding
//             programs. We streamline teaching, improve student engagement, and
//             bridge the gap between academic learning and industry requirements.
//           </p>

//           <ul className="whyCodeXodus__features">
//             <li className="whyCodeXodus__feature">Auto-Evaluation</li>
//             <li className="whyCodeXodus__feature">Flexible Test Scheduling</li>
//             <li className="whyCodeXodus__feature">Comprehensive Analytics</li>
//             <li className="whyCodeXodus__feature">Real-World Projects</li>
//           </ul>
//         </div>
//       </div>

//       <div className="whyCodeXodus__cards">
//         <div className="whyCodeXodus__card whyCodeXodus__card--programs">
//           <div className="whyCodeXodus__card-icon">CX</div>
//           <div className="whyCodeXodus__card-info">
//             <h3>150+</h3>
//             <p>Programs available for students</p>
//           </div>
//         </div>

//         <div className="whyCodeXodus__card whyCodeXodus__card--popular">
//           <div className="whyCodeXodus__card-image">
//             <img src={Image3} alt="Popular Program" />
//           </div>
//           <div className="whyCodeXodus__card-content">
//             <span className="whyCodeXodus__badge">Popular Programs</span>
//             <p>Lorem ipsum dolor sit</p>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default WhyCodeXodus;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./WhyCodeXodus.css";
import Image1 from "../Images/why1.svg";
import Image2 from "../Images/why2.svg";
import Image3 from "../Images/why3.svg";

const WhyCodeXodus = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS with a default duration
  }, []);

  return (
    <section className="whyCodeXodus">
      <h2 className="whyCodeXodus__title" data-aos="fade-up">
        Why CodeXodus for Universities?
      </h2>

      <div className="whyCodeXodus__content" data-aos="fade-up">
        <div className="whyCodeXodus__images" data-aos="fade-right">
          <img
            src={Image1}
            alt="Students walking"
            className="whyCodeXodus__image whyCodeXodus__image--left"
          />
        </div>

        <div className="whyCodeXodus__info" data-aos="fade-left" data-aos-delay="200">
          <p className="whyCodeXodus__description">
            CodeXodus provides a complete solution to enhance university coding programs. We streamline teaching, improve student engagement, and bridge the gap between academic learning and industry requirements.

          </p>

          <ul className="whyCodeXodus__features">
            <li className="whyCodeXodus__feature" data-aos="fade-up" data-aos-delay="300">
              Auto-Evaluation: Reduces faculty workload
            </li>
            <li className="whyCodeXodus__feature" data-aos="fade-up" data-aos-delay="400">
              Flexible Scheduling: Supports mock tests and interviews
            </li>
            <li className="whyCodeXodus__feature" data-aos="fade-up" data-aos-delay="500">
              Regional Language Support: Inclusive coding education
            </li>
            <li className="whyCodeXodus__feature" data-aos="fade-up" data-aos-delay="600">
              AI Assistant Support: Personalized learning guidance
            </li>
            <li className="whyCodeXodus__feature" data-aos="fade-up" data-aos-delay="700">
              Certifications: Level 1, 2, and 3 certificates to validate skills
            </li>
            <li className="whyCodeXodus__feature" data-aos="fade-up" data-aos-delay="800">
              Industry-Ready Skills: Prepares students for successful careers
            </li>
          </ul>

        </div>
      </div>

      <div className="whyCodeXodus__cards">
        <div
          className="whyCodeXodus__card whyCodeXodus__card--programs"
          data-aos="zoom-in"
        >
          <div className="whyCodeXodus__card-icon">CX</div>
          <div className="whyCodeXodus__card-info">
            <h3>150+</h3>
            <p>Programs available for students</p>
          </div>
        </div>

        <div
          className="whyCodeXodus__card whyCodeXodus__card--popular"
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <div className="whyCodeXodus__card-image">
            <img src={Image3} alt="Popular Program" />
          </div>
          <div className="whyCodeXodus__card-content">
            <span className="whyCodeXodus__badge">Popular Programs</span>
            <p>Lorem ipsum dolor sit</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyCodeXodus;
