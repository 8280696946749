// import React from "react";
// import "./NBASupport.css";

// const NBASupport = () => {
//   return (
//     <section className="nbaSupport">
//       <div className="nbaSupport__header">
//         <h2 className="nbaSupport__title">NBA Accreditation Support</h2>
//       </div>
//       <div className="nbaSupport__grid">
//         <div className="nbaSupport__card nbaSupport__card--blue">
//           <h4 className="nbaSupport__card-title">
//             Outcome-Based Education (OBE)
//           </h4>
//           <p className="nbaSupport__card-description">
//             Program Outcomes and Program Specific Outcomes
//           </p>
//           <span className="nbaSupport__badge nbaSupport__badge--blue">
//             CRITERIA 3
//           </span>
//         </div>
//         <div className="nbaSupport__card nbaSupport__card--peach">
//           <h4 className="nbaSupport__card-title">
//             Skill Development and Competency
//           </h4>
//           <p className="nbaSupport__card-description">
//             Vision, Mission, and Program Educational Objectives
//             <br />
//             Curriculum and Teaching-Learning Processes
//           </p>
//           <span className="nbaSupport__badge nbaSupport__badge--peach">
//             CRITERIA 1, 5
//           </span>
//         </div>
//         <div className="nbaSupport__card nbaSupport__card--blue">
//           <h4 className="nbaSupport__card-title">
//             Industry-Relevant Curriculum
//           </h4>
//           <p className="nbaSupport__card-description">
//             Curriculum and Learning Resources
//           </p>
//           <span className="nbaSupport__badge nbaSupport__badge--blue">
//             CRITERIA 4
//           </span>
//         </div>
//         <div className="nbaSupport__card nbaSupport__card--peach">
//           <h4 className="nbaSupport__card-title">
//             Continuous Assessment and Feedback
//           </h4>
//           <p className="nbaSupport__card-description">
//             Continuous Improvement
//             <br />
//             Student Performance
//           </p>
//           <span className="nbaSupport__badge nbaSupport__badge--peach">
//             CRITERIA 6, 7
//           </span>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default NBASupport;
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./NBASupport.css";

const NBASupport = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS with a 1200ms duration
  }, []);

  return (
    <section className="nbaSupport">
      <div className="nbaSupport__header" data-aos="fade-up">
        <h2 className="nbaSupport__title">NBA Accreditation Support with CodeXodus
        </h2>
      </div>
      <div className="nbaSupport__grid">
  {/* Outcome-Based Education (OBE) */}
  <div
    className="nbaSupport__card nbaSupport__card--blue"
    data-aos="fade-right"
    data-aos-delay="200"
  >
    <h4 className="nbaSupport__card-title">Outcome-Based Education (OBE)</h4>
    <p className="nbaSupport__card-description">
      Supports well-defined program outcomes and specific objectives to ensure effective learning.
    </p>
    <span className="nbaSupport__badge nbaSupport__badge--blue">CRITERIA 3</span>
  </div>

  {/* Skill Development & Competency */}
  <div
    className="nbaSupport__card nbaSupport__card--peach"
    data-aos="fade-left"
    data-aos-delay="400"
  >
    <h4 className="nbaSupport__card-title">Skill Development & Competency</h4>
    <p className="nbaSupport__card-description">
      Reinforces vision, mission, and educational goals, focusing on building essential competencies.
    </p>
    <span className="nbaSupport__badge nbaSupport__badge--peach">CRITERIA 1, 5</span>
  </div>

  {/* Industry-Relevant Curriculum */}
  <div
    className="nbaSupport__card nbaSupport__card--blue"
    data-aos="fade-right"
    data-aos-delay="600"
  >
    <h4 className="nbaSupport__card-title">Industry-Relevant Curriculum</h4>
    <p className="nbaSupport__card-description">
      Enriches curriculum and resources to align with industry standards for real-world skills.
    </p>
    <span className="nbaSupport__badge nbaSupport__badge--blue">CRITERIA 4</span>
  </div>

  {/* Continuous Improvement & Feedback */}
  <div
    className="nbaSupport__card nbaSupport__card--peach"
    data-aos="fade-left"
    data-aos-delay="800"
  >
    <h4 className="nbaSupport__card-title">Continuous Improvement & Feedback</h4>
    <p className="nbaSupport__card-description">
      Ongoing assessment, feedback, and performance tracking for sustained growth.
    </p>
    <span className="nbaSupport__badge nbaSupport__badge--peach">CRITERIA 6, 7</span>
  </div>
</div>

    </section>
  );
};

export default NBASupport;
