// import React from "react";
// import "./NEP2020.css";
// import Image from "../Images/gobal.svg";

// const NEP2020 = () => {
//   return (
//     <section className="nep2020">
//       <div className="nep2020__content">
//         <h2 className="nep2020__title">NEP 2020</h2>
//         <p className="nep2020__description">
//           By aligning with NEP 2020, CodeXodus supports institutions in
//           delivering a modern, inclusive, and skill-oriented education that
//           meets the policy’s goals for transformative learning.
//         </p>
//         <ul className="nep2020__features">
//           <li className="nep2020__feature">Multilingual Learning Support</li>
//           <li className="nep2020__feature">Flexible Learning Paths</li>
//           <li className="nep2020__feature">
//             Skill Development and Critical Thinking
//           </li>
//           <li className="nep2020__feature">ICT-Enabled Education</li>
//         </ul>
//       </div>
//       <div className="nep2020__image-container">
//         <img
//           src={Image}
//           alt="Globe with graduation cap"
//           className="nep2020__image"
//         />
//       </div>
//     </section>
//   );
// };

// export default NEP2020;

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./NEP2020.css";
import Image from "../Images/gobal.svg";

const NEP2020 = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS with a duration of 1200ms
  }, []);

  return (
    <section className="nep2020">
      <div className="nep2020__content" data-aos="fade-up">
        <h2 className="nep2020__title">Driving NEP 2020 Vision with CodeXodus
        </h2>
        <p className="nep2020__description">
        CodeXodus is committed to supporting the goals of NEP 2020 by fostering a modern, skill-driven, and inclusive educational experience that transforms the way programming is taught in institutions.

        </p>
        <ul className="nep2020__features">
          <li className="nep2020__feature">Language Diversity: Coding education in regional languages for accessibility.
          </li>
          <li className="nep2020__feature">Flexible Learning Paths: Customized, adaptive learning for skill mastery.
          </li>
          <li className="nep2020__feature">
          Skill Building: Foster technical skills, problem-solving, and critical thinking.

          </li>
          <li className="nep2020__feature">Tech-Enabled Learning: ICT tools for innovative and NEP-aligned education.
          </li>
        </ul>
      </div>
      <div className="nep2020__image-container" data-aos="fade-left" data-aos-delay="200">
        <img
          src={Image}
          alt="Globe with graduation cap"
          className="nep2020__image"
        />
      </div>
    </section>
  );
};

export default NEP2020;
