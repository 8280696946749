// import React from "react";
// import "./Footer.css";
// import Logo from "../Images/logo.png"; // Replace with your logo path
// import Image1 from "../Images/Facebook.svg";
// import Image2 from "../Images/instagram.svg";
// import Image3 from "../Images/linkedin.svg";
// import Image4 from "../Images/youtube.svg";

// const Footer = () => {
//   return (
//     <footer className="footer">
//       <div className="footer__container">
//         <div className="footer__brand-container">
//           <div className="footer__brand">
//             <img src={Logo} alt="CodeXodus Logo" className="footer__logo" />
//             <span className="footer__brand-name">
//               <span className="footer__brand-highlight">COD</span>E
//               <span className="footer__brand-x">X</span>ODUS
//             </span>
//           </div>
//           <div className="footer__social-media">
//             <a
//               href="https://www.facebook.com"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img
//                 src={Image1}
//                 alt="Facebook"
//                 className="footer__social-icon"
//               />
//             </a>
//             <a
//               href="https://www.instagram.com/codexodus1/"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img
//                 src={Image2}
//                 alt="Instagram"
//                 className="footer__social-icon"
//               />
//             </a>
//             <a
//               href="https://www.linkedin.com/company/codexodus/about/"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img
//                 src={Image3}
//                 alt="LinkedIn"
//                 className="footer__social-icon"
//               />
//             </a>
//             <a
//               href="https://www.youtube.com/@codexodus/featured"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img src={Image4} alt="YouTube" className="footer__social-icon" />
//             </a>
//           </div>
//         </div>

//         <div className="footer__links">
//           <div className="footer__links-section">
//             <h4 className="footer__links-title">About</h4>
//             <ul className="footer__list">
//               <li className="footer__list-item">Company Overview</li>
//               <li className="footer__list-item">Partner</li>
//               <li className="footer__list-item">FAQ</li>
//               <li className="footer__list-item">Pricing</li>
//             </ul>
//           </div>
//           <div className="footer__links-section">
//             <h4 className="footer__links-title">Support</h4>
//             <ul className="footer__list">
//               <li className="footer__list-item">Terms & Condition</li>
//               <li className="footer__list-item">Technical Support</li>
//               <li className="footer__list-item">Feedback</li>
//               <li className="footer__list-item">Community Forum</li>
//             </ul>
//           </div>
//           <div className="footer__links-section">
//             <h4 className="footer__links-title">Contact us</h4>
//             <ul className="footer__list">
//               <li className="footer__list-item">+91 7448580100</li>
//               <li className="footer__list-item">support@codexodus.com</li>
//             </ul>
//           </div>
//         </div>
//       </div>
//       <div className="footer__bottom">
//         <p className="footer__copyright">
//           ©2024 CodeXodus · All rights reserved.
//         </p>
//         <ul className="footer__policies">
//           <li className="footer__policy">Term of use</li>
//           <li className="footer__policy">Privacy policy</li>
//           <li className="footer__policy">Security</li>
//         </ul>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

// import React from "react";
// import "./Footer.css";
// import Logo from "../Images/logo.png"; // Replace with your logo path
// import Image1 from "../Images/Facebook.svg";
// import Image2 from "../Images/instagram.svg";
// import Image3 from "../Images/linkedin.svg";
// import Image4 from "../Images/youtube.svg";

// const Footer = () => {
//   return (
//     <footer className="footer">
//       <div className="footer__container">
//         <div className="footer__brand-container">
//           <div className="footer__brand">
//             <img src={Logo} alt="CodeXodus Logo" className="footer__logo" />
//             <span className="footer__brand-name">
//               <span className="footer__brand-highlight">COD</span>E
//               <span className="footer__brand-x">X</span>ODUS
//             </span>
//           </div>
//           <div className="footer__social-media">
//             <a
//               href="https://www.facebook.com"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img
//                 src={Image1}
//                 alt="Facebook"
//                 className="footer__social-icon"
//               />
//             </a>
//             <a
//               href="https://www.instagram.com/codexodus1/"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img
//                 src={Image2}
//                 alt="Instagram"
//                 className="footer__social-icon"
//               />
//             </a>
//             <a
//               href="https://www.linkedin.com/company/codexodus/about/"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img
//                 src={Image3}
//                 alt="LinkedIn"
//                 className="footer__social-icon"
//               />
//             </a>
//             <a
//               href="https://www.youtube.com/@codexodus/featured"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img src={Image4} alt="YouTube" className="footer__social-icon" />
//             </a>
//           </div>
//         </div>

//         <div className="footer__links">
//           <div className="footer__links-section">
//             <h4 className="footer__links-title">About</h4>
//             <ul className="footer__list">
//               <li className="footer__list-item">Company Overview</li>
//               <li className="footer__list-item">Partner</li>
//               <li className="footer__list-item">FAQ</li>
//               <li className="footer__list-item">Pricing</li>
//             </ul>
//           </div>
//           <div className="footer__links-section">
//             <h4 className="footer__links-title">Support</h4>
//             <ul className="footer__list">
//               <li className="footer__list-item">Terms & Condition</li>
//               <li className="footer__list-item">Technical Support</li>
//               <li className="footer__list-item">Feedback</li>
//               <li className="footer__list-item">Community Forum</li>
//             </ul>
//           </div>
//           <div className="footer__links-section">
//             <h4 className="footer__links-title">Contact us</h4>
//             <ul className="footer__list">
//               <li className="footer__list-item">+91 7448580100</li>
//               <li className="footer__list-item">support@codexodus.com</li>
//               <li className="footer__list-item">
//                 MH Colony, Nungambakkam, Chennai - 600034
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//       <div className="footer__bottom">
//         <p className="footer__copyright">
//           ©2024 CodeXodus · All rights reserved.
//         </p>
//         <ul className="footer__policies">
//           <li className="footer__policy">Term of use</li>
//           <li className="footer__policy">Privacy policy</li>
//           <li className="footer__policy">Security</li>
//         </ul>
//       </div>
//     </footer>
//   );
// };

// export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import Logo from "../Images/logo.png"; // Replace with your logo path
import Image1 from "../Images/Facebook.svg";
import Image2 from "../Images/instagram.svg";
import Image3 from "../Images/linkedin.svg";
import Image4 from "../Images/youtube.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__brand-container">
          <div className="footer__brand">
            <img src={Logo} alt="CodeXodus Logo" className="footer__logo" />
            <span className="footer__brand-name">
              <span className="footer__brand-highlight">COD</span>E
              <span className="footer__brand-x">X</span>ODUS
            </span>
          </div>
          <div className="footer__social-media">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Image1}
                alt="Facebook"
                className="footer__social-icon"
              />
            </a>
            <a
              href="https://www.instagram.com/codexodus1/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Image2}
                alt="Instagram"
                className="footer__social-icon"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/codexodus/about/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Image3}
                alt="LinkedIn"
                className="footer__social-icon"
              />
            </a>
            <a
              href="https://www.youtube.com/@codexodus/featured"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Image4} alt="YouTube" className="footer__social-icon" />
            </a>
          </div>
        </div>

        <div className="footer__links">
          <div className="footer__links-section">
            <h4 className="footer__links-title">About</h4>
            <ul className="footer__list">
              <li className="footer__list-item">Company Overview</li>
              <li className="footer__list-item">Partner</li>
              <li className="footer__list-item">FAQ</li>
              <li className="footer__list-item">Pricing</li>
            </ul>
          </div>
          <div className="footer__links-section">
            <h4 className="footer__links-title">Support</h4>
            <ul className="footer__list">
              <li className="footer__list-item">Terms & Condition</li>
              <li className="footer__list-item">
                <a href="mailto:help.codexodus@gmail.com">Technical Support</a>
              </li>
              <li className="footer__list-item">Feedback</li>
              <li className="footer__list-item">Community Forum</li>
            </ul>
          </div>

          <div className="footer__links-section">
            <h4 className="footer__links-title">Contact us</h4>
            <ul className="footer__list">
              <li className="footer__list-item">+91 7448580100</li>
              <li className="footer__list-item">support@codexodus.com</li>
              <li className="footer__list-item">
                MH Colony, Nungambakkam, Chennai - 600034
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <p className="footer__copyright">
          ©2024 CodeXodus · All rights reserved.
        </p>
        <ul className="footer__policies">
          <li className="footer__policy">Term of use</li>
          <li className="footer__policy">Privacy policy</li>
          <li className="footer__policy">Security</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
